import React from 'react'
import './Footer.css'

export default () => (
  <div>
    <a href="https://wa.me/6285777776285?text=%23hello" aria-label="Philoshop Whatsapp Customer Chat" target="_blank" rel="noopener noreferrer">
      <div className="LiveChat" />
    </a>
    <footer className="footer">
      <div className="container taCenter">
        <span>
          © {new Date().getFullYear()} - PT. PHILOSHOP DIGITAL PARTNER.
        </span>
      </div>
    </footer>
  </div>

)



